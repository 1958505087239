<template>
  <div class="work-page western-bulldogs">

    <div class="container-block">
  	 <!-- Header -->
    	<section class="header">
        <img src="/static/images/W_1.jpg"/>
    		<div class="meta" style="margin-top: 10px;">
    			<p class="client">WESTERN BULLDOGS</p>
    			<a class="project">BELONG TO THE WEST</a>
    		</div>
    		<div class="copy container">
    			<div class="col-1">
    				<h1>A club rooted<br/> in place.</h1>
    			</div>
    			<div class="col-2">
    				<p>Every day new people arrive in the West. A new country, a new life, looking for a place to belong. We want the Doggies to be that place.</p>
    			</div>
    		</div>
    	</section>

      <div class="spacer-lg"></div>

      <div class="two-col">
        <div class="col" style="width: 45%; margin-right: -40px; z-index: 2;">
          <img src="/static/images/W_2.jpg"/>
        </div>
        <div class="col" style="width: 60%">
          <img src="/static/images/W_3.jpg"/>
        </div>
      </div>

      <div class="spacer-lg"></div>

      <div class="standout-copy">
        Our art direction reflected the diverse textures of the west.
      </div>

    </div>

    <!-- <div class="spacer-lg"></div> -->

    <div style="padding:56.25% 0 0 0;position:relative;">
      <iframe 
        class="video"
        src="https://player.vimeo.com/video/363714585?title=0&byline=0&portrait=0" 
        style="position:absolute;top:0;left:0;width:100%;height:100%;" 
        frameborder="0" 
        allow="autoplay; fullscreen" 
        allowfullscreen>
      </iframe>
    </div>

    <!-- <video 
      style="z-index: 0"
      width="100%" 
      class="video"
      poster="/static/images/w_estern-bulldogs-4.png">
      <source src="https://player.vimeo.com/video/328548747?title=0&byline=0&portrait=0" type="video/mp4">
    </video> -->

    <div class="container-block">

      <div class="overlay-img" style="margin-top: -14%; z-index: 2; width: 70%; position: relative;">
        <img src="/static/images/W_4.png"/>
      </div>

      <img src="/static/images/W_5.jpg" style="margin-top: -5px;"/>

      <div class="spacer-lg"></div>

      <img src="/static/images/W_6.jpg"/>

      <div class="spacer-lg"></div>

      <img src="/static/images/W_7.png" class="overlay-img-mobile" style="margin-top: -160px; width: 85%;"/>

      <img src="/static/images/W_8.jpg" style="margin-top: -5px;"/>

      <div class="spacer-lg"></div>

      <img src="/static/images/W_9.jpg"/>

      <div class="spacer-lg"></div>

      <img src="/static/images/W_10.jpg"/>

      <div class="footer">
        <div class="prev">
          <router-link to="/fitzroy-lions">
            <img src="/static/images/prev.svg"/>
          </router-link>
        </div>
        <div class="footer-contact">
          <a href="mailto:grant@bloodutd.com,david@bloodutd.com">Drop us a line</a>
          <span>&copy; {{date}} by Blood UTD</span>
        </div>
        <div class="next">
          <router-link to="/zerafa">
            <img src="/static/images/next.svg"/>
          </router-link>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'western-bulldogs',
  data() {
    return {
      date: new Date().getFullYear(),
    }
  },
  mounted() {
    // $(".video").hover(function(event) {
    //     if(event.type === "mouseenter") {
    //         $(this).attr("controls", "");
    //     } else if(event.type === "mouseleave") {
    //         $(this).removeAttr("controls");
    //     }
    // });
    // $('.video').click(function(el) {
    //     this.paused ? this.play() : this.pause();
    // });

    // document.getElementById("poster-image").onclick = function() { 
    //     $(".poster-image").hide();
    //     $(".video")[0].src += "&autoplay=1";
    //  };

  }
}
</script>

<style lang="scss" scoped>

.western-bulldogs {

}

</style>
